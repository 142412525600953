import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import { PhotoCamera } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import { MachineTypePageController } from '../controllers/MachineTypePageController'
import { ImageController } from '../../../../shared/controllers/ImageController'
import { CompanyAssignmentController } from '../../../../users/shared/controllers/CompanyAssignmentController'
import { User } from '../../../../users/models/User'
import { MachineTypeFiles } from './machineTypeFiles'
import { Company, emptyCompany } from '../../../models/Company'
import { MachineType, emptyMachineType } from '../../../models/MachineType'
import { MachineTypePicture } from '../../../shared/components/MachineTypePicture'
import { MachineTypeMainData } from '../../../shared/components/MachineTypeMainData'
import { FileManager } from '../../../../shared/components/fileManager'

export const MachineTypePage = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      deleteButton: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#f44336',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#d32215',
        },
      },
      machineTypePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '240px',
        borderRadius: '50%',
      },
      hiddenInput: {
        display: 'none',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  const machineTypePageController: MachineTypePageController = new MachineTypePageController(
    props.history
  )

  const [isLoading, setIsLoading] = useState(true)
  let [machineType, setMachineType] = useState<MachineType>({
    ...emptyMachineType,
  })
  let [machineTypePicture, setMachineTypePicture] = useState<
    string | ArrayBuffer
  >()

  const change = (key: string, value: string) => {
    machineType[key] = value
    setMachineType({ ...machineType })
  }

  useEffect(() => {
    setIsLoading(true)
    // fetch machineType and more data from backend
    const { params } = props.match
    let getData = async () => {
      // get the machineType by id
      let machineType: MachineType = await machineTypePageController.getMachineTypeById(
        params.machineTypeId
      )
      setMachineType({ ...machineType })

      setIsLoading(false)
    }
    getData()
  }, [])

  return (
    <div>
      <Header
        page={'machineType'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      {isLoading ? (
        <div className={classes.appContent}>
          <div className={classes.dataContainer}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={classes.appContent}>
          <MachineTypePicture
            machineType={machineType}
            machineTypePicture={machineTypePicture}
            setMachineTypePicture={setMachineTypePicture}
            selectedLanguage={props.selectedLanguage}
          />

          <MachineTypeMainData
            machineType={machineType}
            change={change}
            selectedLanguage={props.selectedLanguage}
          />

          <FileManager
            heading={
              {
                de: 'Maschinentyp Dateien',
                en: 'Machinetype Files',
                fr: 'Maschinentyp Dateien - FR',
              }[props.selectedLanguage]
            }
            basePath={['machineTypes', machineType._id, 'files']}
            id={machineType._id}
            type={'machineType'}
            history={props.history}
            selectedLanguage={props.selectedLanguage}
            showRenameOption={true}
            showDeleteOption={true}
            showAddOptions={true}
          />

          {window.localStorage.getItem('loggedInUserRole') === 'admin' ||
          window.localStorage.getItem('loggedInUserRole') ===
            'manufacturerAdmin' ? (
            <div className={classes.dataContainer}>
              <Button
                className={classes.button}
                onClick={() => {
                  // save machineType
                  machineTypePageController.saveMachineType(
                    machineType,
                    machineTypePicture,
                    props.history
                  )
                }}
              >
                {
                  {
                    de: 'speichern',
                    en: 'save',
                    fr: 'speichern - FR',
                  }[props.selectedLanguage]
                }
              </Button>
            </div>
          ) : null}

          {window.localStorage.getItem('loggedInUserRole') === 'admin' ||
          window.localStorage.getItem('loggedInUserRole') ===
            'manufacturerAdmin' ? (
            <div className={classes.dataContainer}>
              <Button
                className={classes.deleteButton}
                onClick={async () => {
                  let confirmation = await confirm(
                    'Maschinentyp wirklich löschen?'
                  )
                  if (confirmation === true) {
                    machineTypePageController.deleteMachineType(
                      machineType,
                      props.history
                    )
                  }
                }}
              >
                {
                  {
                    de: 'löschen',
                    en: 'delete',
                    fr: 'löschen - FR',
                  }[props.selectedLanguage]
                }
              </Button>
            </div>
          ) : null}
        </div>
      )}
      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
