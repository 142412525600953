import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { CreateNewMachineTypeController } from '../controllers/CreateNewMachineTypeController'
import { MachineType, emptyMachineType } from '../../../models/MachineType'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { MachineTypeMainData } from '../../../shared/components/MachineTypeMainData'
import { MachineTypePicture } from '../../../shared/components/MachineTypePicture'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import { Company, emptyCompany } from '../../../models/Company'
import { MachineType, emptyMachineType } from '../../../models/MachineType'

export const CreateNewMachineType = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      machineTypePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '240px',
        borderRadius: '50%',
      },
      hiddenInput: {
        display: 'none',
      },
    })
  )
  const classes = useStyles()

  const createNewMachineTypeController: CreateNewMachineTypeController = new CreateNewMachineTypeController(
    props.history
  )

  const [isLoading, setIsLoading] = useState(false)
  const [machineType, setMachineType] = useState<MachineType>({
    ...emptyMachineType,
  })
  let [machineTypePicture, setMachineTypePicture] = useState<
    string | ArrayBuffer
  >()

  useEffect(() => {
    const { params } = props.match
    change('company', params.companyId)
  }, [])

  const change = (key: string, value: string) => {
    machineType[key] = value
    setMachineType({ ...machineType })
  }

  return (
    <div>
      <Header
        page={'createNewMachineType'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      {isLoading ? (
        <div className={classes.appContent}>
          <div className={classes.dataContainer}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={classes.appContent}>
          <MachineTypePicture
            machineType={machineType}
            machineTypePicture={machineTypePicture}
            setMachineTypePicture={setMachineTypePicture}
          />

          <MachineTypeMainData machineType={machineType} change={change} />

          <div className={classes.dataContainer}>
            <Button
              className={classes.button}
              onClick={() => {
                createNewMachineTypeController.createNewMachineType(
                  machineType,
                  machineTypePicture,
                  props.history
                )
              }}
            >
              {
                {
                  de: 'MachineType erstellen',
                  en: 'create machineType type',
                  fr: 'MachineType erstellen - FR',
                }[props.selectedLanguage]
              }
            </Button>
          </div>
        </div>
      )}

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
