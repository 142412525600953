import React, { useEffect, useState } from 'react'
import {
  Button,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

interface SearchBarProps {
  getBatchOfMachines
  companyId
  machineTypeId
  history
  selectedLanguage
}

export const SearchBar = ({
  getBatchOfMachines,
  companyId,
  machineTypeId,
  history,
  selectedLanguage,
}: SearchBarProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      searchbar: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      },
      textfield: {
        margin: 0,
        width: 200,
        backgroundColor: 'white',
      },
      button: {
        width: 200,
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
    })
  )
  const classes = useStyles()

  const [searchValue, setSearchValue] = useState('')
  const [searchProperty, setSearchProperty] = useState('machineNumber')

  const getLabel = (key) => {
    const labels = {
      machineNumber: 'Maschinennummer',
    }
    return labels[key]
  }

  return (
    <div className={classes.searchbar}>
      <TextField
        className={classes.textfield}
        size="small"
        name="test"
        id="searchValue"
        label={getLabel(searchProperty)}
        variant="filled"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            getBatchOfMachines(searchValue, searchProperty, true)
          }
        }}
      />

      <FormControl variant="filled" size="small">
        <InputLabel htmlFor="outlined-age-native-simple">
          {
            {
              de: 'Suche nach',
              en: 'search',
              fr: 'Suche nach - FR',
            }[selectedLanguage]
          }
        </InputLabel>
        <Select
          className={classes.textfield}
          native
          label="Suche"
          value={searchProperty}
          onChange={(e) => setSearchProperty(e.target.value as string)}
          inputProps={{
            name: 'searchProperty',
            id: 'searchProperty',
          }}
        >
          <option value="machineNumber">Maschinennummer</option>
        </Select>
      </FormControl>

      <Button
        className={classes.button}
        startIcon={<SearchIcon />}
        onClick={() => getBatchOfMachines(searchValue, searchProperty, true)}
      >
        {
          {
            de: 'Suchen',
            en: 'search',
            fr: 'Suchen - FR',
          }[selectedLanguage]
        }
      </Button>

      {window.localStorage.getItem('loggedInUserRole') === 'admin' ||
      window.localStorage.getItem('loggedInUserRole') === 'manufacturerAdmin' ||
      window.localStorage.getItem('loggedInUserRole') ===
        'manufacturerAdminLight' ? (
        <Button
          className={classes.button}
          onClick={() =>
            history.push(`/createNewMachine/${companyId}/${machineTypeId}`)
          }
        >
          {
            {
              de: '+ Maschine',
              en: '+ Machine',
              fr: '+ Maschine - FR',
            }[selectedLanguage]
          }
        </Button>
      ) : null}
    </div>
  )
}
