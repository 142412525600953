import React from 'react'
import {
  AppBar,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'
import { SharedDataProvider } from '../providers/SharedDataProvider'
import { emptyUser } from '../../users/models/User'

interface HeaderProps {
  page
  history
  selectedLanguage
  setSelectedLanguage
}

export const Header = ({
  page,
  history,
  selectedLanguage,
  setSelectedLanguage,
}: HeaderProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      header: {
        backgroundColor: '#ffffff',
        position: 'static',
        height: '7vh',
      },
      marginLeftAuto: {
        display: 'flex',
        flex: 1,
        justifyContent: 'right',
        marginLeft: 'auto',
      },
      marginRightAuto: {
        display: 'flex',
        flex: 1,
        justifyContent: 'left',
        marginRight: 'auto',
      },
      headerIconButton: {
        paddingRight: 0,
      },
      languageItem: {
        justifyContent: 'center',
      },
      logOutItem: {
        justifyContenet: 'center',
        color: 'red',
      },
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: '100%',
      },
      toolbarText: {
        color: '#000000',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 20,
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: '50px',
        cursor: 'pointer',
      },
      toolbarTextLogo: {
        color: '#000000',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 25,
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: '50px',
        cursor: 'pointer',
      },
      toolbarTextactive: {
        textDecoration: 'underline',
      },
    })
  )
  const classes = useStyles()

  const [anchorElAccount, setAnchorElAccount] = React.useState(null)
  const [anchorElLanguages, setAnchorElLanguages] = React.useState(null)
  const openAccount = Boolean(anchorElAccount)
  const openLanguages = Boolean(anchorElLanguages)

  const sharedDataProvider = new SharedDataProvider()

  const handleLanguages = (event) => {
    setAnchorElLanguages(event.currentTarget)
  }

  const handleLanguagesClose = () => {
    setAnchorElLanguages(null)
  }

  const handleAccount = (event) => {
    setAnchorElAccount(event.currentTarget)
  }

  const handleAccountClose = () => {
    setAnchorElAccount(null)
  }

  return (
    <AppBar className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.marginRightAuto}>
          <div className={classes.toolbar}>
            <div className={classes.toolbarTextLogo}>IOPEDIA</div>
            <div
              className={
                classes.toolbarText +
                (page === 'home' ? ' ' + classes.toolbarTextactive : '')
              }
              onClick={() => history.push('/home')}
            >
              {
                {
                  de: 'Home',
                  en: 'Home',
                  fr: 'Home - FR',
                }[selectedLanguage]
              }
            </div>
            {window.localStorage.getItem('loggedInUserRole') === 'admin' ||
            window.localStorage.getItem('loggedInUserRole') ===
              'manufacturerAdmin' ||
            window.localStorage.getItem('loggedInUserRole') ===
              'manufacturerAdminLight' ? (
              <div
                className={
                  classes.toolbarText +
                  (page === 'users' ? ' ' + classes.toolbarTextactive : '')
                }
                onClick={() => history.push('/users')}
              >
                {
                  {
                    de: 'Nutzer',
                    en: 'Users',
                    fr: 'Nutzer - FR',
                  }[selectedLanguage]
                }
              </div>
            ) : null}
            <div
              className={
                classes.toolbarText +
                (page === 'machines' ? ' ' + classes.toolbarTextactive : '')
              }
              onClick={() => {
                if (
                  window.localStorage.getItem('loggedInUserRole') === 'admin'
                ) {
                  history.push('/machines')
                } else {
                  history.push(
                    '/machines/' +
                      window.localStorage.getItem('loggedInUserCompany')
                  )
                }
              }}
            >
              {
                {
                  de: 'Maschinen',
                  en: 'Machines',
                  fr: 'Maschinen - FR',
                }[selectedLanguage]
              }
            </div>
            {window.localStorage.getItem('loggedInUserRole') === 'admin' ||
            window.localStorage.getItem('loggedInUserRole') ===
              'manufacturerAdmin' ||
            window.localStorage.getItem('loggedInUserRole') ===
              'manufacturerAdminLight' ? (
              <div
                className={
                  classes.toolbarText +
                  (page === 'machineTypes'
                    ? ' ' + classes.toolbarTextactive
                    : '')
                }
                onClick={() => {
                  if (
                    window.localStorage.getItem('loggedInUserRole') === 'admin'
                  ) {
                    history.push('/machineTypes')
                  } else {
                    history.push(
                      '/machineTypes/' +
                        window.localStorage.getItem('loggedInUserCompany')
                    )
                  }
                }}
              >
                {
                  {
                    de: 'Maschinentypen',
                    en: 'Machinetypes',
                    fr: 'Maschinentypen - FR',
                  }[selectedLanguage]
                }
              </div>
            ) : null}
            {window.localStorage.getItem('loggedInUserRole') === 'admin' ? (
              <div
                className={
                  classes.toolbarText +
                  (page === 'companies' ? ' ' + classes.toolbarTextactive : '')
                }
                onClick={() => history.push('/companies')}
              >
                {
                  {
                    de: 'Unternehmen',
                    en: 'Companies',
                    fr: 'Unternehmen - FR',
                  }[selectedLanguage]
                }
              </div>
            ) : null}
          </div>
        </div>

        <div className={classes.marginLeftAuto}>
          <div>
            <IconButton
              edge="start"
              aria-label="languages"
              aria-controls="languages-appbar"
              aria-haspopup="true"
              onClick={handleLanguages}
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="languages-appbar"
              anchorEl={anchorElLanguages}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openLanguages}
              onClose={handleLanguagesClose}
            >
              {['de', 'en', 'fr'].map((option) => (
                <MenuItem
                  className={classes.languageItem}
                  key={option}
                  onClick={() => {
                    setSelectedLanguage(option)
                    setAnchorElLanguages(null)
                  }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <div>
            <IconButton
              className={classes.headerIconButton}
              aria-label="account of current user"
              aria-controls="account-appbar"
              aria-haspopup="true"
              onClick={handleAccount}
            >
              <AccountCircle />
            </IconButton>

            <Menu
              id="account-appbar"
              anchorEl={anchorElAccount}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openAccount}
              onClose={handleAccountClose}
            >
              <MenuItem
                className={classes.logOutItem}
                onClick={async () => {
                  // TODO: reactivate
                  /*
                  let response = await fetch(
                    sharedDataProvider.host + '/users/logout',
                    {
                      headers: new Headers({
                        accesstoken: 'Bearer ' + windows.localStorage.getItem('accessToken'),
                        refreshtoken: 'Bearer ' + window.localStorage.getItem('refreshToken'),
                      }),
                    }
                  */
                  // reset locally stored values
                  window.localStorage.setItem('accesstoken', '')
                  window.localStorage.setItem('refreshtoken', '')
                  window.localStorage.setItem('loggedInUserId', '')
                  window.localStorage.setItem('loggedInUserCompany', '')
                  window.localStorage.setItem('loggedInUserRole', '')
                  window.localStorage.setItem('loggedInUserUnlockLevel', '')
                  history.push('/login')
                }}
              >
                {
                  {
                    de: 'Ausloggen',
                    en: 'Logout',
                    fr: 'Ausloggen - FR',
                  }[selectedLanguage]
                }
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}
