import React, { useEffect, useState } from 'react'
import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import loginBackground from '../../../../../documents/login-background.jpeg'
import foerderung from '../../../../../documents/foerderung.gif'
import ClearIcon from '@material-ui/icons/Clear'
import { LoginController } from '../controllers/LoginController'

export const Login = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      loginScreen: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: '#0f76b9',
        backgroundImage: `url(${loginBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      },
      loginText: {
        color: '#ffffff',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 40,
        textAlign: 'center',
        margin: 20,
      },
      loginBox: {
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        padding: 25,
        width: 250,
      },
      loginButton: {
        backgroundColor: '#29235c',
        color: 'white',
        variant: 'contained',
        fontSize: 10,
        padding: 10,
        width: 200,
        margin: 10,
        '&:hover': {
          backgroundColor: '#29235c',
        },
      },
      loginErrorBox: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        backgroundColor: '#fff6f6',
        marginTop: 20,
        padding: 25,
        width: 250,
      },
      notVisible: {
        visibility: 'hidden',
      },
      loginErrorTextH1: {
        color: '#aa5b58',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 20,
        textAlign: 'left',
      },
      loginErrorTextH2: {
        color: '#aa5b58',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 15,
        textAlign: 'left',
      },
      loginErrorClearButton: {
        position: 'absolute',
        top: 0,
        right: 0,
      },
      formControl: {
        marginTop: 15,
        marginBottom: 15,
      },
      textfield: {
        margin: 0,
        width: 200,
        backgroundColor: 'white',
      },
    })
  )
  const classes = useStyles()
  const loginController: LoginController = new LoginController(props.history)

  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')
  const [showErrorBox, setShowErrorBox] = useState(false)

  const login = async () => {
    let { status, data } = await loginController.login(mail, password)
    if (status === 200) {
      window.localStorage.setItem('accesstoken', data.newAccessToken)
      window.localStorage.setItem('refreshtoken', data.newRefreshToken)
      window.localStorage.setItem('loggedInUserId', data.data._id)
      window.localStorage.setItem('loggedInUserCompany', data.data.company)
      window.localStorage.setItem('loggedInUserRole', data.data.role)
      window.localStorage.setItem(
        'loggedInUserUnlockLevel',
        data.data.unlockLevel
      )
      props.history.push(`/home`)
    } else {
      setPassword('')
      setShowErrorBox(true)
    }
  }

  return (
    <div className={classes.loginScreen}>
      <img
        src={foerderung}
        style={{ position: 'absolute', right: '20', bottom: '20' }}
      />
      <div className={classes.loginText}>{'IOPEDIA'}</div>
      <div className={classes.loginBox}>
        <div className={classes.formControl}>
          <TextField
            className={classes.textfield}
            size="small"
            name="test"
            id="mail"
            label="Mail"
            variant="filled"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                login()
              }
            }}
          />
        </div>
        <div className={classes.formControl}>
          <TextField
            className={classes.textfield}
            size="small"
            name="test"
            id="password"
            label="Password"
            variant="filled"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                login()
              }
            }}
          />
        </div>
        <div>
          <Button
            className={classes.loginButton}
            onClick={() => {
              login()
            }}
          >
            {
              {
                de: 'Login',
                en: 'Login',
                fr: 'Login - FR',
              }[props.selectedLanguage]
            }
          </Button>
        </div>
      </div>
      <div
        className={
          classes.loginErrorBox + (showErrorBox ? '' : ' ' + classes.notVisible)
        }
      >
        <div className={classes.loginErrorTextH1}>{'Login failed'}</div>
        <div className={classes.loginErrorTextH2}>{'please try again!'}</div>

        <div className={classes.loginErrorClearButton}>
          <IconButton
            onClick={() => {
              setShowErrorBox(false)
            }}
          >
            <ClearIcon />
          </IconButton>
        </div>
      </div>
    </div>
  )
}
