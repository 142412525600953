import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import 'regenerator-runtime'
import { User } from '../../../models/User'
import { RequestController } from '../../../../shared/controllers/RequestController'
import { ToastController } from '../../../../shared/controllers/ToastController'

export class CompanyCodesController {
  sharedDataProvider: SharedDataProvider
  toastController: ToastController
  requestController: RequestController

  constructor(history) {
    this.sharedDataProvider = new SharedDataProvider()
    this.toastController = new ToastController()
    this.requestController = new RequestController(history)
  }

  public async getCompanyCodes(): Promise<string> {
    let path = this.sharedDataProvider.host + '/companies/getCompanyCodes'
    let data = await this.requestController.getRequest(path)
    return data
  }

  public async saveCompanyCodes(companyCodes: string) {
    let companyCodesObject = {
      companyCodes: JSON.parse(companyCodes),
    }
    let path = this.sharedDataProvider.host + '/companies/saveCompanyCodes'
    let data = await this.requestController.postRequest(
      path,
      companyCodesObject
    )

    // show toast
    this.toastController.showToast_saveCompanyCodes()
  }
}
