export interface Company {
  _id?: string
  dateOfEntry?: Date
  //Firmenname
  name: string
  //Land
  country: string
  //Stadt
  city: string
  //PLZ
  postalCode: string
  //Straße
  street: string
  //Straßen-Nummer
  streetNumber: string
  // Branche
  industry: string
  // Kontakt Person der Firma für die IBL
  contactPerson: string
  // Kontakt für Kunden
  customerContact: string
  // Telefon des Kundenkontaktes
  customerContactPhone: string
  // Mail des Kundenkontakte
  customerContactMail: string
}

export let emptyCompany: Company = {
  name: '',
  country: '',
  city: '',
  postalCode: '',
  street: '',
  streetNumber: '',
  industry: '',
  contactPerson: '',
  customerContact: '',
  customerContactPhone: '',
  customerContactMail: '',
}
