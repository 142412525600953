import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { Company } from '../../companies/models/Company'
import { Machine } from '../../machines/models/Machine'
import { MachineType } from '../../machines/models/MachineType'
import { User } from '../../users/models/User'

export class ToastController {
  constructor() {}

  // companies
  public showToast_createNewCompany(company: Company) {
    let message = company.name + ' wurde erstellt!'
    toast(message)
  }
  public showToast_saveCompany(company: Company) {
    let message = company.name + ' wurde gespeichert!'
    toast(message)
  }
  public showToast_deleteCompany(company: Company) {
    let message = company.name + ' wurde gelöscht!'
    toast(message)
  }

  // users
  public showToast_createNewUser(user: User) {
    let message = user.firstName + ' ' + user.lastName + ' wurde erstellt!'
    toast(message)
  }

  public showToast_saveUser(user: User) {
    let message = user.firstName + ' ' + user.lastName + ' wurde gespeichert!'
    toast(message)
  }

  public showToast_deleteUser(user: User) {
    let message = user.firstName + ' ' + user.lastName + ' wurde gelöscht!'
    toast(message)
  }

  public showToast_makeUserTheContactPerson(user: User) {
    let message =
      user.firstName + ' ' + user.lastName + ' ist jetzt Ansprechpartner!'
    toast(message)
  }

  public showToast_changePassword() {
    let message = 'Password changed!'
    toast(message)
  }

  public showToast_saveCompanyCodes() {
    let message = 'Company Codes wurden gespeichert!'
    toast(message)
  }

  // machines
  public showToast_createNewMachine(machine: Machine) {
    let message = machine.machineNumber + ' wurde erstellt!'
    toast(message)
  }

  public showToast_saveMachine(machine: Machine) {
    let message = machine.machineNumber + ' wurde gespeichert!'
    toast(message)
  }

  public showToast_deleteMachine(machine: Machine) {
    let message = machine.machineNumber + ' wurde gelöscht!'
    toast(message)
  }

  // machine types
  public showToast_createNewMachineType(machineType: MachineType) {
    let message = machineType.name + ' wurde erstellt!'
    toast(message)
  }

  public showToast_saveMachineType(machineType: MachineType) {
    let message = machineType.name + ' wurde gespeichert!'
    toast(message)
  }

  public showToast_deleteMachineType(machineType: MachineType) {
    let message = machineType.name + ' wurde gelöscht!'
    toast(message)
  }
}
