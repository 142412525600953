import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { CreateNewMachineController } from '../controllers/CreateNewMachineController'
import { Machine, emptyMachine } from '../../../models/Machine'
import { Footer } from '../../../../shared/components/Footer'
import { Header } from '../../../../shared/components/Header'
import { MachineMainData } from '../../../shared/components/MachineMainData'
import { CompanyAssignmentController } from '../../../../users/shared/controllers/CompanyAssignmentController'
import { MachinePicture } from '../../../shared/components/MachinePicture'
import { LoadingAnimation } from '../../../../shared/components/LoadingAnimation'
import { Company, emptyCompany } from '../../../models/Company'
import { MachineType, emptyMachineType } from '../../../models/MachineType'

export const CreateNewMachine = (props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appContent: {
        minHeight: '86vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      button: {
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#1c8033',
        color: 'white',
        variant: 'contained',
        margin: 5,
        fontSize: 15,
        '&:hover': {
          backgroundColor: '#1c8033',
        },
      },
      machinePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '240px',
        borderRadius: '50%',
      },
      hiddenInput: {
        display: 'none',
      },
    })
  )
  const classes = useStyles()

  const createNewMachineController: CreateNewMachineController = new CreateNewMachineController(
    props.history
  )

  const [isLoading, setIsLoading] = useState(false)
  const [machineType, setMachineType] = useState<MachineType>(emptyMachineType)
  let [machine, setMachine] = useState<Machine>({ ...emptyMachine })
  let [machinePicture, setMachinePicture] = useState<string | ArrayBuffer>()

  useEffect(() => {
    const { params } = props.match

    let getCompanyAndMachineTypeById = async () => {
      let machineType: MachineType = await createNewMachineController.getMachineTypeById(
        params.machineTypeId
      )
      setMachineType({ ...machineType })

      change('company', params.companyId)
      change('machineType', params.machineTypeId)
    }
    getCompanyAndMachineTypeById()
  }, [])

  const change = (key: string, value: string) => {
    machine[key] = value
    setMachine({ ...machine })
  }

  return (
    <div>
      <Header
        page={'createNewMachine'}
        history={props.history}
        selectedLanguage={props.selectedLanguage}
        setSelectedLanguage={props.setSelectedLanguage}
      />

      {isLoading ? (
        <div className={classes.appContent}>
          <div className={classes.dataContainer}>
            <LoadingAnimation />
          </div>
        </div>
      ) : (
        <div className={classes.appContent}>
          <MachinePicture
            machine={machine}
            machinePicture={machinePicture}
            setMachinePicture={setMachinePicture}
          />

          <MachineMainData
            machine={machine}
            change={change}
            machineType={machineType}
          />

          <div className={classes.dataContainer}>
            <Button
              className={classes.button}
              onClick={() => {
                createNewMachineController.createNewMachine(
                  machine,
                  machinePicture,
                  props.history
                )
              }}
            >
              {
                {
                  de: 'Machine erstellen',
                  en: 'Create Machine',
                  fr: 'Machine erstellen - FR',
                }[props.selectedLanguage]
              }
            </Button>
          </div>
        </div>
      )}

      <Footer
        history={props.history}
        selectedLanguage={props.selectedLanguage}
      />
    </div>
  )
}
