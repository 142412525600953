import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { SharedDataProvider } from '../../../../shared/providers/SharedDataProvider'
import { emptyUser, User } from '../../../../users/models/User'
import { UserPageController } from '../../../../users/pages/userPage/controllers/UserPageController'
import { Company } from '../../../models/Company'

interface ContactPersonProps {
  company: Company
  history
  selectedLanguage
}

export const ContactPerson = ({
  company,
  history,
  selectedLanguage,
}: ContactPersonProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      dataContainerTwo: {
        marginLeft: 20,
        marginRight: 20,
      },
      textfield: {
        margin: 0,
        backgroundColor: 'white',
        width: 200,
      },
      userProfilePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        width: '75px',
        borderRadius: '50%',
        marginRight: 10,
      },
      userDataContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
      },
      userText: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 16,
        textAlign: 'left',
        marginRight: 10,
      },
      heading: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 10,
      },
    })
  )
  const classes = useStyles()
  const sharedDataProvider = new SharedDataProvider()
  const userPageController = new UserPageController(history)

  let [user, setUser] = useState<User>({ ...emptyUser })

  useEffect(() => {
    // fetch user and more data from backend
    let getData = async () => {
      // user
      let user: User = await userPageController.getUserById(
        company.contactPerson
      )
      setUser({ ...user })
    }
    getData()
  }, [])

  return (
    <div>
      <div className={classes.dataContainer}>
        <div className={classes.heading}>
          {
            {
              de: 'Ansprechpartner',
              en: 'Contact Person',
              fr: 'Ansprechpartner - FR',
            }[selectedLanguage]
          }
        </div>
        <div className={classes.userDataContainer}>
          <Link
            key={user._id}
            to={`/user/${user._id}`}
            style={{ textDecoration: 'none' }}
          >
            <img
              className={classes.userProfilePicture}
              src={
                sharedDataProvider.host +
                '/users/getUserProfilePicture?id=' +
                company.contactPerson +
                '&' +
                new Date()
              }
            ></img>
          </Link>
          <div className={classes.dataContainerTwo}>
            <div className={classes.userText}>
              {user.firstName + ' ' + user.lastName}
            </div>
            <div className={classes.userText}>
              {{
                de: 'E-Mail: ',
                en: 'Mail: ',
                fr: 'E-Mail - FR:',
              }[selectedLanguage] + user.mail}
            </div>
            <div className={classes.userText}>
              {{
                de: 'Telefon: ',
                en: 'Phone: ',
                fr: 'Telefone - FR:',
              }[selectedLanguage] + user.phone}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
