import 'regenerator-runtime'
import { AuthenticationController } from './AuthenticationController'

export class RequestController {
  authenticationController: AuthenticationController
  history: any

  constructor(history: any) {
    this.authenticationController = new AuthenticationController()
    this.history = history
  }

  public async getRequest(path) {
    let response = await fetch(path, {
      headers: new Headers({
        accesstoken: 'Bearer ' + window.localStorage.getItem('accesstoken'),
        refreshtoken: 'Bearer ' + window.localStorage.getItem('refreshtoken'),
      }),
    })

    if (response.status === 200) {
      // parse response
      let responseData = await response.json()

      // update authentication data
      let authenticationController = new AuthenticationController()
      authenticationController.updateAuthenticationData({
        newTokensGenerated: responseData.newTokensGenerated,
        newAccessToken: responseData.newAccessToken,
        newRefreshToken: responseData.newRefreshToken,
      })

      // return main data
      return responseData.data
    } else if (response.status === 401) {
      // TODO: clear local storage and go to /login
      console.log('not authorized')
      window.localStorage.setItem('accesstoken', '')
      window.localStorage.setItem('refreshtoken', '')
      window.localStorage.setItem('loggedInUserId', '')
      window.localStorage.setItem('loggedInUserCompany', '')
      window.localStorage.setItem('loggedInUserRole', '')
      window.localStorage.setItem('loggedInUserUnlockLevel', '')
      this.history.push('/login')
    }
  }

  public async postRequest(path, body) {
    let response = await fetch(path, {
      method: 'POST',
      headers: new Headers({
        accesstoken: 'Bearer ' + window.localStorage.getItem('accesstoken'),
        refreshtoken: 'Bearer ' + window.localStorage.getItem('refreshtoken'),
        'Content-Type': 'application/json;charset=utf-8',
      }),
      body: JSON.stringify(body),
    })

    if (response.status === 200) {
      // parse response
      let responseData = await response.json()

      // update authentication data
      let authenticationController = new AuthenticationController()
      authenticationController.updateAuthenticationData({
        newTokensGenerated: responseData.newTokensGenerated,
        newAccessToken: responseData.newAccessToken,
        newRefreshToken: responseData.newRefreshToken,
      })

      // return main data
      return responseData.data
    } else if (response.status === 401) {
      // TODO: clear local storage and go to /login
      // TODO: clear local storage and go to /login
      console.log('not authorized')
      window.localStorage.setItem('accesstoken', '')
      window.localStorage.setItem('refreshtoken', '')
      window.localStorage.setItem('loggedInUserId', '')
      window.localStorage.setItem('loggedInUserCompany', '')
      window.localStorage.setItem('loggedInUserRole', '')
      window.localStorage.setItem('loggedInUserUnlockLevel', '')
      this.history.push('/login')
    }
  }
}
