import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import React from 'react'
import { SharedDataProvider } from '../../../shared/providers/SharedDataProvider'
import { MachineType } from '../../models/MachineType'

interface MachineMainDataProps {
  machine
  change
  machineType: MachineType
  selectedLanguage
}

export const MachineMainData = ({
  machine,
  change,
  machineType,
  selectedLanguage,
}: MachineMainDataProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dataContainer: {
        marginTop: 30,
        marginLeft: 20,
        marginRight: 20,
      },
      textfield: {
        margin: 0,
        backgroundColor: 'white',
        width: 200,
      },
      seperator: {
        borderBottom: '1px solid lightgrey',
        marginTop: '10px',
        marginBottom: '10px',
      },
      heading: {
        color: '#212121',
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 10,
        marginTop: 50,
      },
      companyAndMachineTypeContainer: {
        display: 'flex',
        justifyContent: 'center',
      },
      companyAndMachineTypeDisplay: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 20,
        marginLeft: 20,
      },
      companyLogo: {
        objectFit: 'contain',
        objectPosition: 'center',
        height: '120px',
        width: '180px',
      },
      machinePicture: {
        objectFit: 'contain',
        objectPosition: 'center',
        height: '120px',
        borderRadius: '50%',
      },
    })
  )
  const classes = useStyles()

  const sharedDataProvider = new SharedDataProvider()

  return (
    <div>
      <div className={classes.heading}>
        {
          {
            de: 'Maschinendaten',
            en: 'Machine Main Data',
            fr: 'Maschinendate - FR',
          }[selectedLanguage]
        }
      </div>
      <div className={classes.seperator}></div>

      <div className={classes.companyAndMachineTypeContainer}>
        <div className={classes.companyAndMachineTypeDisplay}>
          <img
            className={classes.companyLogo}
            src={
              sharedDataProvider.host +
              '/companies/getCompanyLogo?id=' +
              machine.company +
              '&' +
              new Date()
            }
          ></img>
        </div>
        <div className={classes.companyAndMachineTypeDisplay}>
          <img
            className={classes.machinePicture}
            src={
              sharedDataProvider.host +
              '/machineTypes/getMachineTypePicture?id=' +
              machineType._id +
              '&' +
              new Date()
            }
          ></img>
          {machineType.name}
        </div>
      </div>

      <div className={classes.dataContainer}>
        <TextField
          disabled={
            window.localStorage.getItem('loggedInUserRole') !== 'admin' &&
            window.localStorage.getItem('loggedInUserRole') !==
              'manufacturerAdmin' &&
            window.localStorage.getItem('loggedInUserRole') !==
              'manufacturerAdminLight'
          }
          className={classes.textfield}
          size="small"
          id="machineNumber"
          label="Maschinennummer"
          variant="filled"
          defaultValue={machine.machineNumber}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          disabled={
            window.localStorage.getItem('loggedInUserRole') !== 'admin' &&
            window.localStorage.getItem('loggedInUserRole') !==
              'manufacturerAdmin' &&
            window.localStorage.getItem('loggedInUserRole') !==
              'manufacturerAdminLight'
          }
          className={classes.textfield}
          id="dateOfProduction"
          size="small"
          label="Produktionsdatum"
          type="date"
          variant="filled"
          defaultValue={machine.dateOfProduction}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>

      <div className={classes.dataContainer}>
        <TextField
          disabled={
            window.localStorage.getItem('loggedInUserRole') !== 'admin' &&
            window.localStorage.getItem('loggedInUserRole') !==
              'manufacturerAdmin' &&
            window.localStorage.getItem('loggedInUserRole') !==
              'manufacturerAdminLight'
          }
          className={classes.textfield}
          id="dateOfInitialRegistration"
          size="small"
          label="Datum der Erstzulassung"
          type="date"
          variant="filled"
          defaultValue={machine.dateOfInitialRegistration}
          onBlur={(e) => change(e.target.id, e.target.value as string)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  )
}
